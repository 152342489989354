import { OrganizationDetailsLayout } from '../components/Organization/Details';

export function OrganizationDetailRoute() {
  return <OrganizationDetailsLayout />;
}

export const Component = OrganizationDetailRoute;

export function clientLoader() {
  return null;
}
